<template>
  <div>
    <section class="bg-half bg-soft-primary d-table w-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level">
              <h1 class="title mb-0">Termos de Serviço</h1>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>

    <section class="section pt-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-9">

            <div class="card shadow rounded border-0">
              <div class="card-body">
                <h6>Versão 1.0 – 16/05/2023</h6>

                <h5>GLOSSÁRIO – INFORMAÇÕES INICIAIS. </h5>

                <p class="text-muted text-justify">Termos e seus respectivos significados para o correto entendimento deste REGULAMENTO: </p>

                <p class="text-muted text-justify">* "<b>deep-link</b>”: são links que apontam para conteúdo web, de dentro de um aplicativo ou para dentro de um aplicativo. </p>

                <p class="text-muted text-justify">* “<b>page-scrape</b>”: web <b>scraping</b> é uma técnica de extração de dados utilizada para coletar dados de sites por meio de processos automatizados, implementados usando um rastreador <b>bot</b>, esse tipo de “raspagem” de informações é uma forma de realizar cópias de dados em que informações específicas são coletadas e copiadas da web, tipicamente em um banco de dados ou planilha local central, para posterior recuperação ou análise. </p>

                <p class="text-muted text-justify">* “<b>robôs</b>”: sistema autônomo que realiza buscas nas páginas de sistemas na internet (automatizados). Estes sistemas podem estabelecer inúmeras conexões simultâneas para realizar operações em sítios de internet. </p>

                <p class="text-muted text-justify">* “<b>spider</b>”: também conhecido como <b>Robô, Robot, Bot</b> ou <b>Crawler</b>. São programas usados pelos mecanismos de busca para explorar a internet de maneira automática e fazer download de conteúdo web de sites web. </p>

                <h5>CAPÍTULO I – DO SISTEMA</h5>

                <p class="text-muted text-justify">1.1 – A ContrataGov é uma ferramenta/sistema informatizado desenvolvido pela Empresa <b>CONTRATAGOV SOLUÇÕES EM TECNOLOGIA LTDA</b>, pessoa jurídica de direito privado, inscrita no CNPJ nº 39.666.233/0001-63, que possibilita a realização de processos de compras pelo Poder Público, por intermédio da Internet, de bens e serviços junto a fornecedores previamente cadastrados. </p>

                <p class="text-muted text-justify">1.2 – As modalidades de licitações e compras públicas oferecidas pela CONTRATAGOV estão especificadas no manual de operações disponível aos usuários, mas, em resumo, abrange: Pregões Eletrônicos, Dispensa de Licitação, Credenciamento , Leilão e Concorrência. </p>

                <p class="text-muted text-justify">1.3 – Os processos de licitações e compras públicas encontrados no site de cada ente público são de responsabilidade do órgão público que o está publicando, a ContrataGov é a plataforma, meio, utilizado para realização do processo. A mesma é responsável pela ferramenta/sistema e oferece suporte para uso do mesmo para os órgão e para os fornecedores. </p>

                <h5>CAPÍTULO II – DOS USUÁRIOS</h5>

                <p class="text-muted text-justify">2.1 – Pessoas físicas; </p>

                <p class="text-muted text-justify">2.2 – Pessoas jurídicas; </p>

                <p class="text-muted text-justify">2.3 – Sistemas automatizados (webservices, API’s, robôs, etc); </p>

                <h5>CAPÍTULO III – DAS CARACTERÍSTICAS DO SISTEMA</h5>

                <p class="text-muted text-justify">3.1 – A ferramenta/sistema da CONTRATAGOV está estruturada com funcionalidades gerais e específicas, de acordo as Leis Federais nº 8.666/93 e 10.520/02, bem como, em relação ao Decreto Federal nº 10.024/2019 e a Lei 14.133/2021. </p>

                <p class="text-muted text-justify">3.2 – As funcionalidades gerais são de acesso comum a todos os interessados no ambiente público. </p>

                <p class="text-muted text-justify">3.3 – As funcionalidades específicas são de acesso restrito aos usuários e seus representantes legais (ambiente restrito), e permitem a realização de todos os procedimentos pertinentes e necessários em todas as fases das modalidades licitatórias disponíveis em cada portal do ente público. </p>

                <p class="text-muted text-justify">3.4 – A ferramenta/sistema da CONTRATAGOV contempla a possibilidade de consultas públicas com acesso irrestrito. </p>

                <p class="text-muted text-justify">3.5 – Todas as transações realizadas na plataforma da CONTRATAGOV serão registradas e seus usuários identificados, sendo utilizados procedimentos de segurança, tais como: segurança criptográfica, acesso com certificado digital, histórico de acessos por chaves e senhas. </p>

                <p class="text-muted text-justify">3.6 – O sistema também permite que no decorrer da fase de lances do processo licitatório, o usuário, dentro de um prazo definido pelo licitante, possa interpor recursos. </p>

                <h5>CAPÍTULO IV – DO CADASTRAMENTO</h5>

                <p class="text-muted text-justify">4.1 – Para utilização da ferramenta/sistema do ente público será necessário o prévio cadastramento do usuário neste site e sua devida validação. </p>

                <p class="text-muted text-justify">4.2 – No processo de cadastramento, o usuário fornecerá os dados necessários para o preenchimento do cadastro. </p>

                <p class="text-muted text-justify">4.3 – Poderá nomear representante(s), o(s) qual(is) será(ão) reconhecido(s) como legítimo(s) para realizar(em) negócios em seu nome e sob sua responsabilidade, devendo também ser(em) cadastrado(s). </p>

                <p class="text-muted text-justify">4.4 – O fornecedor deverá enviar os documentos necessários para sua habilitação eletronicamente direto na plataforma da do ente público, para fins de validação. </p>

                <p class="text-muted text-justify">4.4.1 – Não será permitido ao fornecedor qualquer forma de participação em processos licitatórios antes da sua plena validação. </p>

                <p class="text-muted text-justify">4.4.2 – Os documentos necessários para habilitação são: </p>

                <p class="text-muted text-justify">4.4.2.1 – <b>Pessoa Jurídica: </b> </p>

                <p class="text-muted text-justify">4.4.2.1.1 – Cartão do CNPJ; </p>

                <p class="text-muted text-justify">4.4.2.1.2 – Última atualização do Contrato Social; </p>

                <p class="text-muted text-justify">4.4.2.1.3 – Documento de Identificação do Responsável Legal; </p>

                <p class="text-muted text-justify">4.4.2.1.4 – Procuração, quando o responsável não constar no Contrato Social. </p>

                <p class="text-muted text-justify">4.4.2.2 – <b>Pessoa Física: </b></p>

                <p class="text-muted text-justify">4.4.2.2.1 – Documento de Identificação com foto; </p>

                <p class="text-muted text-justify">4.5 – Após a validação do cadastro, o usuário e seus representantes legais estarão habilitados para acessar todas as funcionalidades, que lhe couberem, na ferramenta/sistema do ente público. </p>

                <p class="text-muted text-justify">4.6 – Após validação o cadastro terá vigência por 12 meses, posteriormente mesmo deve ser renovado, para fins de atualização cadastral. </p>

                <p class="text-muted text-justify">4.6.1 – A não renovação cadastral pode acarretar em bloqueio no uso da ferramenta/sistema e participação em processos de contratações publicados do ente público. </p>

                <p class="text-muted text-justify">4.7 – O cadastro só será finalizado, após o recebimento da documentação. </p>

                <p class="text-muted text-justify">4.8 – A CONTRATAGOV terá um prazo mínimo de 24 (vinte e quatro) horas e máximo de 72 (setenta e duas) horas para efetivar o credenciamento do usuário/fornecedor. </p>

                <h5>CAPÍTULO V – ACESSO A FERRAMENTA/SISTEMA</h5>

                <p class="text-muted text-justify">5.1 – O acesso a ferramenta/sistema exigirá do usuário e de seus representantes legais o uso de senha pessoal. </p>

                <p class="text-muted text-justify">5.2 – Os usuários e seus representantes legais responsabilizam-se pela utilização correta da senha em todas as transações efetuadas no sistema, não cabendo ao ente público ou a CONTRATAGOV a responsabilidade por eventuais danos decorrentes do uso indevido da senha, inclusive por terceiros. </p>

                <h5>CAPÍTULO VI – DIREITOS E RESPONSABILIDADES DOS USUÁRIOS</h5>

                <p class="text-muted text-justify">6.1 – Responder por todas as transações que forem efetuadas em seu nome no sistema, assumindo como firmes e verdadeiras todas as suas propostas e lances. </p>

                <p class="text-muted text-justify">6.2 – Responder pela veracidade e exatidão de todas as descrições dos bens e serviços ofertados. Caso ocorra oferta de bens e serviços em desacordo com as especificações definidas em edital pelo órgão licitador, o proponente assumirá todas as responsabilidades civis e criminais daí decorrentes, inclusive prejuízos causados ao ente público, a CONTRATAGOV e a terceiros. </p>

                <p class="text-muted text-justify">6.3 – Observar e cumprir todas as leis e regulamentações, inclusive fiscais e tributárias, aplicáveis aos bens e serviços que ofereça. </p>

                <p class="text-muted text-justify">6.4 – Acompanhar as suas operações no sistema, ficando responsável pela perda de negócios em decorrência do não cumprimento de prazos ou quaisquer outros avisos emitidos na ferramenta/sistema e não observados pela falta de acompanhamento, inclusive de sua desconexão da ferramenta/sistema. </p>

                <p class="text-muted text-justify">6.5 – O usuário ou o seu representante, quando da adesão a ferramenta/sistema, implica na presunção de sua capacidade técnica para realização das transações na ferramenta/sistema e expressa sua total concordância aos termos deste Regulamento, não podendo alegar, posteriormente, desinformação, nem discordância com relação aos seus termos. </p>

                <p class="text-muted text-justify">6.6 – O Usuário concorda que, ao usar a ferramenta/sistema, <b>não irá: </b> </p>

                <p class="text-muted text-justify">6.6.1 – Utilizar qualquer <b>“deep-link”, “page-scrape”</b>, “robôs”, <b>“spider”</b> ou outro dispositivo automático, programa, algoritmo ou metodologia ou qualquer processo semelhante ou equivalente manual, para recuperar, indexar, fazer <b>“scrape” </b>, “garimpar dados”, acessar, adquirir, copiar, monitorar ou de alguma forma reproduzir ou driblar a estrutura de navegação ou a apresentação da CONTRATAGOV ou qualquer parte do serviço ou qualquer conteúdo do sistema, ou de qualquer forma reproduzir ou burlar a estrutura ou apresentação de navegação dos serviço ofertados ou qualquer conteúdo do site, para obter ou tentar obter quaisquer materiais, documentos ou informações através de quaisquer meios não intencionalmente disponibilizados através do site/sistema; </p>

                <p class="text-muted text-justify">6.6.2 – Modificar ou copiar os materiais do site; </p>

                <p class="text-muted text-justify">6.6.3 – Usar os materiais para qualquer finalidade comercial ou para exibição pública (comercial ou não comercial); </p>

                <p class="text-muted text-justify">6.6.4 – Tentar descompilar ou fazer engenharia reversa de qualquer software contido neste site; </p>

                <p class="text-muted text-justify">6.6.5 – Remover quaisquer direitos autorais ou outras notações de propriedade dos materiais; </p>

                <p class="text-muted text-justify">6.7 – A utilização do sistema poderá ser automaticamente rescindida se o usuário violar alguma dessas restrições. </p>

                <h5>CAPÍTULO VII – DA RESPONSABILIDADE</h5>

                <p class="text-muted text-justify">7.1 – Manter a ferramenta/sistema em funcionamento conforme parâmetros estabelecidos neste Regulamento, comprometendo-se em analisar e disponibilizar, a seu critério, quando necessárias e viáveis, alterações e implementações para melhoria do sistema; </p>

                <p class="text-muted text-justify">7.2 – Restabelecer o mais rápido possível, a ferramenta/sistema, quando eventualmente ocorrer a sua indisponibilidade, por motivos técnicos ou falhas na Internet ou por outras circunstâncias alheias à vontade da CONTRATAGOV, não assumindo qualquer responsabilidade pela ocorrência; </p>

                <p class="text-muted text-justify">7.3 – Suspender ou indisponibilizar a utilização da ferramenta/sistema, mediante prévio aviso aos usuários, em função da necessidade de realização de manutenção, reparos inadiáveis, alterações e outras exigências técnicas; </p>

                <p class="text-muted text-justify">7.4 – Manter sigilo das transações realizadas na ferramenta/sistema, prestando somente as informações necessárias à concretização dos negócios; </p>

                <p class="text-muted text-justify">7.5 – Manter uma central de atendimento ou serviço de e-mail para prestar serviço de atendimento aos usuários da ferramenta/sistema. </p>

                <h5>CAPÍTULO VIII – COMPARTILHAMENTO DE INFORMAÇÕES DE FORNECEDORES DO SISTEMA</h5>

                <p class="text-muted text-justify">8.1 – A CONTRATAGOV empenha-se em garantir a privacidade e segurança das informações prestadas pelos seus usuários. Para viabilizar que os parceiros da CONTRATAGOV ofertem produtos e serviços exclusivos e dedicados ao poder público, às empresas e ao cidadão em geral, é necessário que haja compartilhamento de algumas dessas informações, o que é realizado com responsabilidade e seguindo todos os parâmetros registrados neste regulamento. </p>

                <p class="text-muted text-justify">8.2 – Ao se cadastrar na ferramenta/sistema, o usuário concorda com os Termos deste Regulamento e com a Política de Segurança e Privacidade da CONTRATAGOV, de compartilhamento de informações entre os parceiros e portais devidamente cadastrados, a exemplo: </p>

                <p class="text-muted text-justify">8.2.1 – Marketplace de serviços para empresas, que tem como objetivo estabelecer parceiros para ofertarem produtos e serviços de alta qualidade e estratégicos para os fornecedores cadastrados na ferramenta/sistema; </p>

                <p class="text-muted text-justify">8.2.2 – A CONTRATAGOV pode compartilhar dados pessoais em caso de requisição judicial; </p>

                <p class="text-muted text-justify">8.2.3 – Com autorização do fornecedor, havendo a necessidade de compartilhamento das informações, enviaremos ao cliente uma notificação solicitando sua aprovação ou reprovação; </p>

                <p class="text-muted text-justify">8.2.4 – Compartilhamento de informações do fornecedor para a construção do Banco de Preços da CONTRATAGOV e da Dispensa Eletrônica; </p>

                <p class="text-muted text-justify">8.3 – O fornecedor não tem obrigação de aceitar as ofertas oriundas do Marketplace da CONTRATAGOV ou Parceiros e Empresas Terceiras, porém, caso opte por contratar qualquer produto ou serviços ofertado, é de sua inteira responsabilidade a negociação com a empresa ofertante, não tendo a CONTRATAGOV, qualquer responsabilidade civil, penal, moral, trabalhista ou outra qualquer com o contrato firmado entre o fornecedor/empresa cadastrada na CONTRATAGOV com o parceiro do Marketplace da CONTRATAGOV. </p>

                <h5>CAPÍTULO IX- DOS CUSTOS DO SISTEMA</h5>

                <p class="text-muted text-justify">9.1 – A ferramenta/sistema é gratuita para as entidades compradoras, seja o Poder Público ou equiparado, seja entidades privadas que queiram utilizar a plataforma da CONTRATAGOV para realizar suas compras. </p>

                <p class="text-muted text-justify">9.2 – Conforme disposto no Artigo 175, §1º, da Lei Federal nº 14.133/21, a CONTRATAGOV cobra uma taxa dos fornecedores para ressarcimento dos custos de utilização de recursos de tecnologia da informação, suporte, e acompanhamento assistido aos fornecedores. Portanto, não constitui tarifação de serviços, mas apenas o ressarcimento dos custos gerados, sendo estes revertidos em melhorias e manutenção dos serviços envolvidos; </p>

                <p class="text-muted text-justify">9.2.1 – Na utilização da ferramenta/sistema para as modalidades de licitações Pregão Eletrônico, Dispensa Eletrônica, Leilão Eletrônico, Concorrência Eletrônica e Credenciamento Eletrônico, poderá a CONTRATAGOV cobrar do fornecedor os custos para utilização da plataforma digital, a título de ressarcimento dos custos e melhorias no sistema. </p>

                <p class="text-muted text-justify">9.2.2 – O Fornecedor dependerá da escolha de uma forma/modalidade de ressarcimento para o envio de proposta, o acesso a plataforma e demais funções é gratuita. E desta forma, o mesmo está concordando com as vinculações financeiras daquela modalidade. </p>

                <h5>9.3 – Ressarcimento e Custos pela utilização da Plataforma/Sistema</h5>

                <p class="text-muted text-justify">9.3.1 – A CONTRATAGOV aplica nas modalidades Pregão Eletrônico, Dispensa Eletrônica, Concorrência Eletrônica, Leilão Eletrônico apenas uma forma de ressarcimento de custos pelo uso da tecnologia, sendo o <b>Plano Por Processo</b>. </p>

                <p class="text-muted text-justify">9.3.2 – O valor a ser cobrado consistirá em valor fixo, seguindo a seguinte forma: </p>

                <p class="text-muted text-justify">9.3.3 – Os valores atuais são: a) <b>Pregão Eletrônico, Concorrência Eletrônica, Leilão Eletrônico:</b> R$ 199,00 (cento e noventa e nove reais); b) <b>Dispensa Eletrônica:</b> R$ 99,00 (noventa e nove reais). </p>

                <p class="text-muted text-justify">9.3.4 – Caso o processo venha a ser cancelado, o fornecedor poderá requerer à restituição da taxa efetiramente paga, desde que, o faça no prazo máximo de 10 (dez) dias do cancelamento do processo. </p>

                <p class="text-muted text-justify">9.3.5 – Ao escolher o plano desejado o Fornecedor desde já, reconhece que o seu cadastro poderá ser liberado em até 02 (duas) horas após a confirmação do pagamento, em dias úteis. Assim, a CONTRATAGOV não assume qualquer prejuízo que o Fornecedor venha a ter pela não participação em processo licitatório quando este tenha feito o cadastro prévio não respeitando o prazo precitado de no mínimo 02 (duas) horas, em dias úteis. </p>

                <p class="text-muted text-justify">9.3.6 – A CONTRATAGOV a qualquer tempo e a seu critério, poderá alterar ou reajustar os preços das tarifas de serviços ou dos custos gerados, comprometendo-se a dar divulgação dessas mudanças através de seu site na internet, envio de e-mail aos fornecedores cadastrados; </p>

                <p class="text-muted text-justify">9.3.7 – A CONTRATAGOV utiliza plataformas de pagamentos independentes, a fim de garantir maior segurança aos seus fornecedores. </p>

                <p class="text-muted text-justify">9.3.8 – A CONTRATAGOV não armazena em seus bancos de dados quaisquer tipos de informações de pagamento como números de cartão de crédito, senhas, etc. Toda transação é realizada de maneira transparente por plataformas próprias de pagamento; </p>

                <p class="text-muted text-justify">9.3.9 – Caso sua escolha de pagamento seja via boleto bancário a liberação do acesso à Plataforma de Licitações será condicionada à confirmação do pagamento, sendo que ele pode demorar até 2 dias úteis. Seja para acesso ao credenciamento ou bloqueio do sistema após data de vencimento da fatura. </p>

                <p class="text-muted text-justify">9.3.10 – Para ter o acesso liberado de forma imediata o fornecedor deve encaminhar, por um dos meios de comunicação, o comprovante de pagamento. </p>

                <p class="text-muted text-justify">9.3.11 – No caso de não pagamento das taxas de ressarcimento, sua conta continuará existindo, porém será considerada uma conta gratuita, sendo o seu acesso limitado à algumas funcionalidades da ferramenta/sistema. </p>

                <p class="text-muted text-justify">9.3.12 – As associações e ou fundações de direito privado ou público sem fins lucrativos, ficarão isentas dos pagamentos dos valores previstos no presente artigo, devendo, contudo, encaminhar para o e-mail contratagov@startgov.com.br, informando sua condição de instituição sem fins lucrativos, bem como, enviar a seguinte documentação: </p>

                <p class="text-muted text-justify">a) Cópia do estatuto ou contrato social registrado no cartório competente e suas alterações; </p>
                <p class="text-muted text-justify">b) Relação nominal atualizada dos dirigentes da entidade, com Cadastro de Pessoas Físicas – CPF; </p>
                <p class="text-muted text-justify">c) Atestado de Registro e Certificado de Entidade Beneficente de Assistência Social, expedido pelo Conselho Nacional de Assistência Social, nos termos da lei; e</p>
                <p class="text-muted text-justify">d) comprovante de entrega da Declaração de Informações Econômico-fiscais da Pessoa Jurídica – DIPJ, como entidade imune ou isenta, fornecido pelo setor competente do Ministério da Fazenda. </p>

                <p class="text-muted text-justify">9.3.13 - São isentas também de pagamento, às pessoas físicas ou jurídicas vinculadas ao programa da Agricultura Familiar, nos termos da Lei Federal nº 11.326/2006.</p>

                <p class="text-muted text-justify">9.3.13.1 – O procedimento para solicitar a isenção para o Agricultor(a) Familiar será: </p>

                <p class="text-muted text-justify">a) O envio para o email: <b>contratagov@startgov.com.br</b> dos seguintes documentos: </p>
                <p class="text-muted text-justify">1. Cópia do certificado de agricultor Familiar; </p>
                <p class="text-muted text-justify">2. Documento de identificação do requisitante; </p>
                <p class="text-muted text-justify">3. Número do Processo de Compra que deseja participar. </p>

                <h5>CAPÍTULO X – DO PROCESSO DE LICITAÇÃO</h5>

                <p class="text-muted text-justify">10.1 – As modalidades e os procedimentos de cada processo de compra ou os referentes a aquisição de bens e serviços nos casos de dispensa, inexigibilidade de licitação ou credenciamento, observarão as disposições legais vigentes. </p>

                <p class="text-muted text-justify">10.2 – A forma, meio e prazo de pagamento dos bens e serviços licitados ou adquiridos serão ajustados entre o licitador e os usuários/fornecedores. </p>

                <p class="text-muted text-justify">10.3 – Os tributos e contribuições incidentes sobre o bem ou serviço, serão de responsabilidade do licitante/usuário/fornecedor, de acordo com as normas legais e regras aplicáveis. </p>

                <p class="text-muted text-justify">10.4 – A CONTRATAGOV não se responsabiliza pelo cálculo, retenção e recolhimento de tributos e contribuições eventualmente incidentes nas operações/negociações, a cargo dos usuários/fornecedores. </p>

                <p class="text-muted text-justify">10.5 – O processo de transferência do bem negociado ou prestação do serviço é de inteira e absoluta responsabilidade do licitante e do usuário/fornecedor, não cabendo a CONTRATAGOV qualquer participação ou responsabilidade. </p>

                <h5>CAPÍTULO XI – DA RESCISÃO</h5>

                <p class="text-muted text-justify">11.1 – A adesão a este REGULAMENTO poderá ser rescindida pelo usuário, mediante comunicação escrita, com antecedência mínima de 30 (trinta) dias, e somente será efetivada após o cumprimento de todas as obrigações assumidas e adimplemento de qualquer débito junto a CONTRATAGOV, caso exista. </p>

                <h5>CAPÍTULO XII – DA VIGÊNCIA</h5>

                <p class="text-muted text-justify">12.1 – A adesão ao presente REGULAMENTO terá vigência por tempo indeterminado, salvo nos casos de rescisão e exclusão por iniciativa do Licitante/Usuário/Fornecedor ou da CONTRATAGOV. </p>

                <h5>CAPÍTULO XIII – DA DESCONTINUIDADE</h5>

                <p class="text-muted text-justify">13.1 – A CONTRATAGOV reserva o direito de, a qualquer momento, modificar ou descontinuar, temporária ou permanentemente, os serviços ou qualquer parte deles, com ou sem aviso prévio. </p>

                <p class="text-muted text-justify">13.2 – O usuário/fornecedor concorda que não será responsabilizado por qualquer modificação, suspensão ou interrupção dos serviços ou qualquer parte dele. </p>

                <p class="text-muted text-justify">13.3 – Em caso de descontinuidade da ferramenta/sistema da CONTRATAGOV, nos termos do item 13.1, a CONTRATAGOV garante o funcionamento do mesmo por até 30 (trinta) dias após a data do anúncio de descontinuidade. </p>

                <p class="text-muted text-justify">13.4 – O usuário/fornecedor não poderá responsabilizar a CONTRATAGOV nem seus diretores, executivos, funcionários, afiliados, agentes, contratados ou licenciadores por quaisquer modificações, suspensões ou descontinuidades do SISTEMA. </p>

                <p class="text-muted text-justify">13.5 – Os materiais exibidos no site da CONTRATAGOV podem incluir erros técnicos, tipográficos ou fotográficos. A CONTRATAGOV não garante que qualquer material em seu site seja preciso, completo ou atual. A CONTRATAGOV pode fazer alterações nos materiais contidos em seu site a qualquer momento, com ou sem aviso prévio. </p>

                <h5>CAPÍTULO XIV – DAS DISPOSIÇÕES FINAIS</h5>

                <p class="text-muted text-justify">14.1 – A CONTRATAGOV poderá modificar, a qualquer tempo, os termos e condições deste regulamento e notificará os participantes através da publicação de uma versão atualizada no site da plataforma/sistema do ente público. O novo regulamento valerá para as propostas incluídas a partir da sua publicação no Sistema, na internet. </p>

                <p class="text-muted text-justify">14.2 – A CONTRATAGOV reserva-se no direito de cancelar a habilitação do usuário e de seus representantes, para acesso a ferramenta/sistema, seja em razão de uso indevido do sistema, de violação de lei ou por descumprimento qualquer item deste regulamento.</p>

                <p class="text-muted text-justify">14.3 – Fica eleito o foro da Comarca de Imperatriz - MA, ou, a critério da CONTRATAGOV, o do domicílio do usuário/fornecedor, excluindo-se qualquer outro por mais privilegiado que seja, para dirimir qualquer controvérsia que ocorrer em relação ao estabelecido neste Regulamento e alterações posteriores.</p>

                <p class="text-muted text-justify">Imperatriz - MA, 16 de maio de 2023.</p>

                <h6>CONTRATAGOV SOLUÇÕES EM TECNOLOGIA LTDA</h6>
                <h6>CNPJ Nº 39.666.233/0001-63</h6>


                <a href="javascript:window.print()" class="btn btn-soft-primary d-print-none">Imprimir</a>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
    }
  },
  computed: {
    pricePerProcess() {
      let value = 'R$ 199,00 (cento e noventa e nove reais)'

      const host = window.location.host.replace('www.', '')
      if(['licitaalema.com.br'].includes(host)) {
        value = 'R$ 149,00 (cento e quarenta e nove reais)'
      }

      return value
    }
  }

};
</script>


